import { Button, Form } from 'antd/es';
import { useState } from 'react';

import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Server } from '../../api/server-class';
import { IsErrorResDto } from '../../api/shared-types/error-res.dto';
import { FloatInput } from '../../components/input/float-input';
import { LoginTypeEnum } from '../../types/types';
import { EmailSendSuccessFullyComponent } from '../register/components/email-sent-succefully.component';

export const ForgottenPasswordPage = function ForgottenPasswordPage() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [showAfterSubmitMessage, setShowAfterSubmitMessage] = useState(false);
  const [form] = Form.useForm();
  const { loginType } = useParams<{ loginType: LoginTypeEnum }>();

  const onFormSubmit = async (value: any) => {
    setLoading(true);
    const response = await Server.PublicForgottenPassword.post({
      email: value.email,
      loginType: loginType!,
    });
    setLoading(false);

    if (!IsErrorResDto(response)) {
      setShowAfterSubmitMessage(true);
    }
  };
  return (
    <Spin spinning={loading}>
      <h1 className="text-center text-uppercase fw-bold">Forgot password?</h1>
      {showAfterSubmitMessage && <EmailSendSuccessFullyComponent email={form.getFieldValue('email')} />}
      <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 24 }}
        className="my-2"
        onFinish={onFormSubmit}
        hidden={showAfterSubmitMessage}
        form={form}
      >
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: `${t('forgottenPasswordPage.form.email.required')}`,
              type: 'email',
            },
          ]}
        >
          <FloatInput label={t('forgottenPasswordPage.form.email.label')} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading} block>
            {t('forgottenPasswordPage.form.button.label')}
          </Button>
        </Form.Item>
      </Form>
      {/* <RegistrationLinkComponent /> */}
    </Spin>
  );
};
