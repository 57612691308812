export const LookingForTheKatAppComponent = function LookingForTheKatAppComponent() {
  return (
    <div className="container">
      <i>
        Looking for KAT App? 
        <div><a href="https://apps.apple.com/us/app/kinestheticawarenesstraining/id6446675760" target="_blank" rel="noreferrer">IOS</a></div>
        <div><a href="https://play.google.com/store/apps/details?id=com.panthertech.kat" target="_blank" rel="noreferrer">Android</a></div>
      </i>
    </div>
  );
};
