import { Button, Form, Grid } from 'antd/es';
import { FormInstance } from 'antd/es/form';
import { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { Server } from '../../api/server-class';
import { IsErrorResDto } from '../../api/shared-types/error-res.dto';
import { FloatInput } from '../../components/input/float-input';
import { LoginTypeEnum } from '../../types/types';
import { ForgottenPasswordLinkComponent } from '../register/components/forgotten-password-link.component';
import { LookingForTheKatAppComponent } from '../register/components/looking-for-the-kat-app.component';
export const LoginPage: React.FC = function LoginPage() {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const form = useRef<FormInstance | null>(null);
  const sizing = Grid.useBreakpoint();
  const { loginType } = useParams<{ loginType: LoginTypeEnum }>();

  const onFinish = async (values: any): Promise<void> => {
    setLoading(true);

    const response = await Server.PublicTokenIssuer.post({
      email: values.email,
      password: values.password,
      loginType: loginType!,
    });

    setLoading(false);
    if (IsErrorResDto(response)) {
      const errorExists = i18n.exists(`errorStatusCodes.${response.statusCode}`);
      if (errorExists) {
        setError(t(`errorStatusCodes.${response.statusCode}`));
      } else {
        setError(response.message.join('\n'));
      }
    } else {
      let url = response.data.redirectUrl;
      window.location.href = url;
    }

    //form?.current?.resetFields(['password']);
  };

  return (
    <>
      <Spin spinning={loading}>
        <h1 className="text-center text-uppercase fw-bold">Login to the Web Portal</h1>
        <h2 className="text-center text-uppercase" style={{ fontSize: '0.9rem' }}>
          <i>To check your account, update your profile and payment method etc.</i>
        </h2>

        <Form ref={form} onFinish={onFinish} labelCol={{ span: 8 }} wrapperCol={{ span: 24 }}>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: `${t('loginPage.form.email.required')}`,
                type: 'email',
              },
            ]}
          >
            <FloatInput label={t('loginPage.form.email.label')} type="email" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: `${t('loginPage.form.password.required')}`,
                type: 'string',
              },
              {
                min: 8,
                message: 'Password must be 8 or more characters',
              },
            ]}
            style={{ marginBottom: 24 }}
          >
            <FloatInput type="password" label={t('loginPage.form.password.label')} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading} block>
              {t('loginPage.form.button.label')}
            </Button>
          </Form.Item>
        </Form>
      </Spin>
      <p></p>
      <ForgottenPasswordLinkComponent />
      {/* <RegistrationLinkComponent /> */}
      {/* <ResendRegistrationLinkComponent /> */}
      <div className="mt-1">
        <LookingForTheKatAppComponent />
      </div>
    </>
  );
};
